import React from 'react'
import OfferDocumentsDesktop from './OfferDocumentsDesktop'
import OfferDocumentsTablet from './OfferDocumentsTablet'
import OfferDocumentsMobile from './OfferDocumentsMobile'
import useOfferDocuments from './useOfferDocuments'
import { OfferDocumentsProps } from './offerDocumentsTypes'

const OfferDocuments = (props: OfferDocumentsProps) => {
  const presentationalProps = useOfferDocuments(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OfferDocumentsMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OfferDocumentsTablet {...presentationalProps} />
  }
  return <OfferDocumentsDesktop {...presentationalProps} />
}

export default OfferDocuments
