import React from 'react'
import { Box, Typography } from '@mui/material'
import millify from 'millify'
import { PortfolioHomeDetailsPresentationalProps } from './portfolioHomeDetailsTypes'

const PortfolioHomeDetailsMobile = (
  props: PortfolioHomeDetailsPresentationalProps
) => {
  const {
    leads,
    finalOfferPrice,
    itemsToShow,
    handleLoadMore,
    isFinalValuation,
  } = props

  const showRangedOffer = !isFinalValuation

  // Get sums of offer range bounds, instead of rounded total +/- 4%
  const finalRangeLowerUnmillified = leads.reduce(
    (total: number, lead) =>
      total +
      Math.trunc(
        ((lead.valuationObject?.finalOfferPrice as number) * 0.96) / 1000
      ) *
        1000,
    0
  )

  const millifyOfferBound = (offerBound: number) =>
    millify(offerBound || 0, {
      precision: offerBound < 1000000 ? 0 : 2,
      lowercase: true,
      space: false,
      units: ['', 'k', 'm', 'b', 't'],
    })

  const finalRangeUpperUnmillified = leads.reduce(
    (total: number, lead) =>
      total +
      Math.ceil(
        ((lead.valuationObject?.finalOfferPrice as number) * 1.04) / 1000
      ) *
        1000,
    0
  )

  const finalRangeLower = millify(finalRangeLowerUnmillified, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })
  const finalRangeUpper = millify(finalRangeUpperUnmillified, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="8px"
        sx={{
          borderRadius: '8px',
          backgroundColor: 'gray1.main',
          padding: '16px',
        }}
      >
        {leads.slice(0, itemsToShow).map((item) => (
          <Box display="flex" flexDirection="row" gap="8px">
            <Typography variant="p3" sx={{ width: '112px' }} noWrap>
              {item.address?.streetNumber} {item.address?.formattedStreet}
            </Typography>
            <Typography variant="p3" sx={{ width: '120px', textAlign: 'end' }}>
              {showRangedOffer
                ? `$${millifyOfferBound(
                    Math.trunc(item.valuationObject?.finalOfferPrice! * 0.96)
                  )} - $${millifyOfferBound(
                    Math.ceil(item.valuationObject?.finalOfferPrice! * 1.04)
                  )}`
                : `${item.valuationObject?.finalOfferPrice?.toLocaleString()}`}
            </Typography>
          </Box>
        ))}
        {/* Show load more button if there are more items */}
        {itemsToShow < leads.length && (
          <Typography
            variant="p3"
            color="green4.main"
            onClick={handleLoadMore}
            sx={{ alignSelf: 'center', cursor: 'pointer', fontWeight: '500' }}
          >
            Load {Math.min(8, leads.length - itemsToShow)} more assets
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="8px"
        sx={{
          borderRadius: '8px',
          backgroundColor: 'green0.main',
          padding: '16px',
          boxShadow: 8,
        }}
      >
        <Box display="flex" flexDirection="row" gap="8px">
          <Typography
            variant="c1"
            color="moneyGreen.main"
            sx={{ width: '112px', fontWeight: 500 }}
          >
            {isFinalValuation ? 'Final' : 'Initial'} valuation{' '}
            {showRangedOffer ? 'range' : ''}
          </Typography>
          <Typography
            variant="c1"
            color="moneyGreen.main"
            sx={{ width: '120px', textAlign: 'end', fontWeight: 500 }}
          >
            {showRangedOffer
              ? `$${finalRangeLower} - $${finalRangeUpper}`
              : `${finalOfferPrice.toLocaleString()}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PortfolioHomeDetailsMobile
