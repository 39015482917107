import {
  BathroomIcon,
  BedroomIcon,
  HouseOutlinedIcon,
  PortfolioMapbox,
} from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import { MAPBOX_TOKEN } from '../../../constants'
import PrelimOfferSection from './PrelimOfferSection'

// Takes two points and returns the quadrant that
// the second point is in relative to the first.
// 2 | 1
// 3 | 4
const getRelativeQuadrant = (
  latitude: number,
  longitude: number,
  assetLatitude: number,
  assetLongitude: number
): number => {
  if (assetLongitude > longitude) {
    if (assetLatitude > latitude) {
      return 1
    } else {
      return 4
    }
  } else if (assetLatitude > latitude) {
    return 2
  } else {
    return 3
  }
}

const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371e3
  const lat1Radians = (lat1 * Math.PI) / 180
  const lat2Radians = (lat2 * Math.PI) / 180
  const latChange = ((lat2 - lat1) * Math.PI) / 180
  const lonChange = ((lon2 - lon1) * Math.PI) / 180

  const a =
    Math.sin(latChange / 2) * Math.sin(latChange / 2) +
    Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.sin(lonChange / 2) *
      Math.sin(lonChange / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c * 0.000621371 // distance in miles
  return d
}

const desktopConfig = {
  containerStyle: {
    height: '640px',
    borderRadius: '24px',
  },
  center: [-94.9903, 37.7392],
  startingZoom: 0,
}
const tabletConfig = {
  containerStyle: {
    height: '544px',
    borderRadius: '24px',
  },
}
const mobileConfig = {
  containerStyle: {
    height: '304px',
    borderRadius: '24px',
  },
  center: [-94.9903, 37.7392],
  startingZoom: 0,
}

type PrelimNeighborhoodSectionProps = {
  street: string
  city: string
  state: string
  propertyType: string
  bedrooms: number
  bathrooms: number
  latitude: number
  longitude: number
  hideNeighbors: () => void
}

const PrelimNeighborhoodSection = (props: PrelimNeighborhoodSectionProps) => {
  const {
    street,
    city,
    state,
    propertyType,
    bedrooms,
    bathrooms,
    latitude,
    longitude,
    hideNeighbors,
  } = props
  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query {
      getPropertySummaryDetails {
        propertyDetails {
          address {
            city
            formattedStreet
            latitude
            longitude
            state
            unit
            zipcode
          }
          baths
          beds
          halfBaths
          propertyType
        }
      }
    }
  `)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { propertyDetails } = getPropertySummaryDetails

  const { assetBounds, processedAssetArray } = useMemo(() => {
    // Marshall assets into a cleaner format
    const cleanedAssets = propertyDetails.map((asset: any) => ({
      ...asset,
      ...asset.address,
      baths: asset.baths + (asset.halfBaths / 2 || 0),
    }))

    const seenLatitudeLongitudes: string[] = []

    // Nearby assets
    const nearbyAssets = cleanedAssets.filter(
      (asset: { latitude: number; longitude: number }) => {
        // Only use assets that are within 5 miles and at different locations.
        const latLngKey = `${asset.latitude},${asset.longitude}`
        if (seenLatitudeLongitudes.includes(latLngKey)) {
          return false
        }
        seenLatitudeLongitudes.push(latLngKey)

        return (
          haversineDistance(
            asset.latitude,
            asset.longitude,
            latitude,
            longitude
          ) < 5
        )
      }
    )

    // If there's not enough assets nearby, we hide this section.
    if (nearbyAssets.length < 2) {
      hideNeighbors()
      return {
        assetBounds: null,
        processedAssetArray: null,
      }
    }

    const asset1 = nearbyAssets[0]
    const asset2 = nearbyAssets[1]

    // Sums up beds/baths from assets that are at the same location (ie. multifamily)
    let asset1Beds = 0
    let asset1Baths = 0
    for (let x = 0; x < cleanedAssets.length; x += 1) {
      if (
        cleanedAssets[x].latitude === asset1.latitude &&
        cleanedAssets[x].longitude === asset1.longitude
      ) {
        asset1Beds += cleanedAssets[x].beds
        asset1Baths += cleanedAssets[x].baths
      }
    }
    asset1.beds = asset1Beds
    asset1.baths = asset1Baths

    let asset2Beds = 0
    let asset2Baths = 0
    for (let x = 0; x < cleanedAssets.length; x += 1) {
      if (
        cleanedAssets[x].latitude === asset2.latitude &&
        cleanedAssets[x].longitude === asset2.longitude
      ) {
        asset2Beds += cleanedAssets[x].beds
        asset2Baths += cleanedAssets[x].baths
      }
    }
    asset2.beds = asset2Beds
    asset2.baths = asset2Baths

    // These are for determining how to orient the popup windows relative
    // to the points and to each other.
    const asset1Quadrant = getRelativeQuadrant(
      latitude,
      longitude,
      asset1.latitude,
      asset1.longitude
    )
    const asset2Quadrant = getRelativeQuadrant(
      latitude,
      longitude,
      asset2.latitude,
      asset2.longitude
    )

    let centerVerticalOrientation = 'center'
    let centerHorizontalOrientation = 'center'
    let asset1VerticalOrientation = 'center'
    let asset1HorizontalOrientation = 'center'
    let asset2VerticalOrientation = 'center'
    let asset2HorizontalOrientation = 'center'
    if (asset1Quadrant === asset2Quadrant) {
      // If in the same quadrant, we choose the opposite quadrant for the center orientation
      // Whichever asset has a higher latitude gets the top orientation and the other gets the bottom
      switch (asset1Quadrant) {
        case 1:
          centerVerticalOrientation = 'top'
          centerHorizontalOrientation = 'right'
          asset1HorizontalOrientation = 'left'
          asset2HorizontalOrientation = 'left'
          break
        case 2:
          centerVerticalOrientation = 'top'
          centerHorizontalOrientation = 'left'
          asset1HorizontalOrientation = 'right'
          asset2HorizontalOrientation = 'right'
          break
        case 3:
          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'left'
          asset1HorizontalOrientation = 'right'
          asset2HorizontalOrientation = 'right'
          break
        case 4:
          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'right'
          asset1HorizontalOrientation = 'left'
          asset2HorizontalOrientation = 'left'
          break
        default:
          break
      }

      if (asset1.latitude > asset2.latitude) {
        asset1VerticalOrientation = 'bottom'
        asset2VerticalOrientation = 'top'
      } else {
        asset1VerticalOrientation = 'top'
        asset2VerticalOrientation = 'bottom'
      }
    } else if (Math.abs(asset1Quadrant - asset2Quadrant) === 2) {
      // The two points are in opposite quadrants
      switch (asset1Quadrant) {
        case 1:
          asset1VerticalOrientation = 'bottom'
          asset1HorizontalOrientation = 'left'
          asset2VerticalOrientation = 'top'
          asset2HorizontalOrientation = 'right'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'right'
          break
        case 2:
          asset1VerticalOrientation = 'bottom'
          asset1HorizontalOrientation = 'right'
          asset2VerticalOrientation = 'top'
          asset2HorizontalOrientation = 'left'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'left'
          break
        case 3:
          asset1VerticalOrientation = 'top'
          asset1HorizontalOrientation = 'right'
          asset2VerticalOrientation = 'bottom'
          asset2HorizontalOrientation = 'left'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'right'
          break
        case 4:
          asset1VerticalOrientation = 'top'
          asset1HorizontalOrientation = 'left'
          asset2VerticalOrientation = 'bottom'
          asset2HorizontalOrientation = 'right'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'left'
          break
        default:
          break
      }
    } else {
      // The two points are on the same side. Place the center house to the opposite of the first asset
      switch (asset1Quadrant) {
        case 1:
          asset1VerticalOrientation = 'bottom'
          asset1HorizontalOrientation = 'left'

          centerVerticalOrientation = 'top'
          centerHorizontalOrientation = 'right'
          break
        case 2:
          asset1VerticalOrientation = 'bottom'
          asset1HorizontalOrientation = 'right'

          centerVerticalOrientation = 'top'
          centerHorizontalOrientation = 'left'
          break
        case 3:
          asset1VerticalOrientation = 'top'
          asset1HorizontalOrientation = 'right'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'left'
          break
        case 4:
          asset1VerticalOrientation = 'top'
          asset1HorizontalOrientation = 'left'

          centerVerticalOrientation = 'bottom'
          centerHorizontalOrientation = 'right'
          break
        default:
          break
      }
      switch (asset2Quadrant) {
        case 1:
          asset2VerticalOrientation = 'bottom'
          asset2HorizontalOrientation = 'left'
          break
        case 2:
          asset2VerticalOrientation = 'bottom'
          asset2HorizontalOrientation = 'right'
          break
        case 3:
          asset2VerticalOrientation = 'top'
          asset2HorizontalOrientation = 'right'
          break
        case 4:
          asset2VerticalOrientation = 'top'
          asset2HorizontalOrientation = 'left'
          break
        default:
          break
      }
    }

    // This is the lead's asset.
    const centerAsset = {
      formattedStreet: street,
      city,
      state,
      propertyType,
      beds: bedrooms,
      baths: bathrooms,
      latitude,
      longitude,
      orientation: `${centerVerticalOrientation}-${centerHorizontalOrientation}`,
      isMainProperty: true,
    }

    const processedAsset1 = {
      ...asset1,
      orientation: `${asset1VerticalOrientation}-${asset1HorizontalOrientation}`,
    }
    const processedAsset2 = {
      ...asset2,
      orientation: `${asset2VerticalOrientation}-${asset2HorizontalOrientation}`,
    }

    const newProcessedAssetArray = [processedAsset1, processedAsset2]
    if (!isMobile) {
      newProcessedAssetArray.push(centerAsset)
    }
    // Determines the bounding box to use for the map to show all of the assets.
    let leftMostPoint = 99999
    let rightMostPoint = -99999
    let topMostPoint = -99999
    let bottomMostPoint = 99999

    newProcessedAssetArray.forEach((asset) => {
      if (asset.latitude > topMostPoint) {
        topMostPoint = asset.latitude
      }
      if (asset.longitude > rightMostPoint) {
        rightMostPoint = asset.longitude
      }
      if (asset.latitude < bottomMostPoint) {
        bottomMostPoint = asset.latitude
      }
      if (asset.longitude < leftMostPoint) {
        leftMostPoint = asset.longitude
      }
    })

    // We add 0.05 to the bounds so that there's some margin for the assets to show
    return {
      assetBounds: [
        [leftMostPoint - 0.05, bottomMostPoint - 0.05],
        [rightMostPoint + 0.05, topMostPoint + 0.05],
      ],
      processedAssetArray: newProcessedAssetArray,
    }
  }, [
    latitude,
    longitude,
    isMobile,
    bathrooms,
    bedrooms,
    city,
    hideNeighbors,
    propertyDetails,
    propertyType,
    state,
    street,
  ])

  if (!processedAssetArray || !assetBounds) {
    return null
  }

  return (
    <PrelimOfferSection
      sectionId="neighbors"
      nextId="portfolio"
      nextCta={
        isMobile
          ? "You're joining a portfolio."
          : "You're joining a professionally managed, diversified portfolio."
      }
    >
      <Grid
        item
        sm={6}
        md={12}
        display="flex"
        flexDirection="column"
        gap="40px"
      >
        <Typography variant="h2" alignSelf="flex-start" color="moneyGreen.main">
          Meet your Flock neighbors.
        </Typography>
        <Box width="100%">
          <PortfolioMapbox
            propertyAssets={processedAssetArray}
            mapboxToken={MAPBOX_TOKEN || ''}
            desktopConfig={desktopConfig}
            tabletConfig={tabletConfig}
            mobileConfig={mobileConfig}
            defaultBounds={assetBounds}
            disableZoom
            disableFullscreen
            detailedPropertyView
          />
        </Box>
      </Grid>
      {isMobile && (
        <>
          {processedAssetArray.map((asset: any) => {
            const {
              formattedStreet,
              city: assetCity,
              state: assetState,
              beds,
              baths,
              propertyType: assetPropertyType,
            } = asset

            return (
              <Grid
                item
                xs={2}
                key={`${formattedStreet}-${beds}-${baths}-${assetPropertyType}-details`}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  padding="24px"
                  gap="8px"
                  borderRadius="24px"
                  sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    overflow="hidden"
                  >
                    <Typography
                      variant="p1"
                      textOverflow="ellipsis"
                      maxWidth="96px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {formattedStreet}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="p2"
                        textOverflow="ellipsis"
                        display="inline-block"
                        maxWidth="62px"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {assetCity}
                      </Typography>
                      <Typography display="inline-block" variant="p2">
                        , {assetState}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Box display="flex" alignItems="center" gap="32px">
                      <Box display="flex" gap="8px" alignItems="center">
                        <BedroomIcon width="13px" height="13px" />
                        <Typography variant="p3">{asset.beds}B </Typography>
                      </Box>
                      <Box display="flex" gap="8px" alignItems="center">
                        <BathroomIcon width="13px" height="13px" />
                        <Typography variant="p3">{asset.baths}B</Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                      <HouseOutlinedIcon width="13px" height="13px" />
                      <Typography variant="p3">
                        {assetPropertyType === 'SFR' ||
                        assetPropertyType === 'Single-family detached home'
                          ? 'SFR'
                          : assetPropertyType}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </>
      )}
    </PrelimOfferSection>
  )
}

export default PrelimNeighborhoodSection
