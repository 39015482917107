import React from 'react'
import PortfolioHomeDetailsDesktop from './PortfolioHomeDetailsDesktop'
import PortfolioHomeDetailsMobile from './PortfolioHomeDetailsMobile'
import usePortfolioHomeDetails from './usePortfolioHomeDetails'
import { PortfolioHomeDetailsProps } from './portfolioHomeDetailsTypes'

const PortfolioHomeDetails = (props: PortfolioHomeDetailsProps) => {
  const presentationalProps = usePortfolioHomeDetails(props)
  const { isMobile } = presentationalProps

  if (isMobile) {
    return <PortfolioHomeDetailsMobile {...presentationalProps} />
  }
  return <PortfolioHomeDetailsDesktop {...presentationalProps} />
}

export default PortfolioHomeDetails
