import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const DownloadIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M19.5 22.0719H17.1844V14.5C17.1844 14.3625 17.0719 14.25 16.9344 14.25H15.0594C14.9219 14.25 14.8094 14.3625 14.8094 14.5V22.0719H12.5C12.2906 22.0719 12.175 22.3125 12.3031 22.475L15.8031 26.9031C15.8265 26.933 15.8564 26.9572 15.8905 26.9738C15.9246 26.9904 15.9621 26.999 16 26.999C16.038 26.999 16.0754 26.9904 16.1095 26.9738C16.1436 26.9572 16.1735 26.933 16.1969 26.9031L19.6969 22.475C19.825 22.3125 19.7094 22.0719 19.5 22.0719Z"
        fill={fillStyle || '#136D42'}
      />
      <path
        d="M25.3563 11.4594C23.925 7.68438 20.2781 5 16.0063 5C11.7344 5 8.0875 7.68125 6.65625 11.4563C3.97813 12.1594 2 14.6 2 17.5C2 20.9531 4.79687 23.75 8.24687 23.75H9.5C9.6375 23.75 9.75 23.6375 9.75 23.5V21.625C9.75 21.4875 9.6375 21.375 9.5 21.375H8.24687C7.19375 21.375 6.20312 20.9562 5.46562 20.1969C4.73125 19.4406 4.34062 18.4219 4.375 17.3656C4.40312 16.5406 4.68437 15.7656 5.19375 15.1125C5.71562 14.4469 6.44688 13.9625 7.25938 13.7469L8.44375 13.4375L8.87813 12.2937C9.14688 11.5812 9.52187 10.9156 9.99375 10.3125C10.4596 9.71471 11.0114 9.18922 11.6313 8.75313C12.9156 7.85 14.4281 7.37187 16.0063 7.37187C17.5844 7.37187 19.0969 7.85 20.3813 8.75313C21.0031 9.19063 21.5531 9.71562 22.0187 10.3125C22.4906 10.9156 22.8656 11.5844 23.1344 12.2937L23.5656 13.4344L24.7469 13.7469C26.4406 14.2031 27.625 15.7437 27.625 17.5C27.625 18.5344 27.2219 19.5094 26.4906 20.2406C26.132 20.6013 25.7054 20.8873 25.2355 21.082C24.7656 21.2768 24.2618 21.3763 23.7531 21.375H22.5C22.3625 21.375 22.25 21.4875 22.25 21.625V23.5C22.25 23.6375 22.3625 23.75 22.5 23.75H23.7531C27.2031 23.75 30 20.9531 30 17.5C30 14.6031 28.0281 12.1656 25.3563 11.4594Z"
        fill={fillStyle || '#136D42'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default DownloadIcon
