import { useMediaQuery, useTheme } from '@mui/material'
import {
  FlexibleCashFlowProps,
  FlexibleCashFlowPresentationalProps,
} from './flexibleCashflowCalculatorTypes'

const useAppreciationPotential: (
  props: FlexibleCashFlowProps
) => FlexibleCashFlowPresentationalProps = (props: FlexibleCashFlowProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    initialValuation,
    mortgageAmount,
    onboardingFee,
    equityOverride,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    closingCosts,
  } = props

  // This is valuation, less all fees and reductions
  const feeModifier = 1 - onboardingFee
  const startingInvestment =
    equityOverride ||
    Math.round(
      Math.round(initialValuation * feeModifier) -
        Math.round(initialValuation * closingCosts) -
        Math.round(mortgageAmount) -
        Math.round(rentReduction) -
        Math.round(daysInRemodelDeduction) -
        Math.round(cashTakeout)
    ) ||
    0

  return {
    ...props,

    isMobile,
    isTablet,

    startingInvestment,
  }
}

export default useAppreciationPotential
