import { Box, Typography } from '@mui/material'
import React from 'react'
import OfferPagePaper from '../OfferPagePaper'
import { OfferDocumentsPresentationalProps } from './offerDocumentsTypes'

const DocumentsRenderer = React.lazy(
  () => import('../../OfferPageComponents/DocumentsRenderer')
)

const OfferDocumentsMobile = (props: OfferDocumentsPresentationalProps) => {
  const { documentConfigs, slackThreadUrl, isSSR } = props
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="cta2">Document Center </Typography>
      <OfferPagePaper>
        <Box display="flex" flexDirection="column" gap="16px">
          {documentConfigs?.map((documentConfig) => {
            const { title, documents } = documentConfig
            if (documents.length > 0 && !isSSR) {
              return (
                <React.Suspense fallback="">
                  <DocumentsRenderer
                    title={title}
                    documents={documents}
                    slackThreadUrl={slackThreadUrl}
                  />
                </React.Suspense>
              )
            }
            return null
          })}
        </Box>
      </OfferPagePaper>
    </Box>
  )
}

export default OfferDocumentsMobile
